import React, {useState} from "react";
import Layout from "../components/layout";
import {STRINGS} from "../constants/strings";
import {URL} from "../constants/urls";

const ContactUs = () => {
    const [contactSubmitted, setContactSubmitted] = useState(false);
    const [submissionStart, setSubmissionStart] = useState(false);

    return (
        <Layout>
            <div className="wrapper">
                <img src={"/images/contact-us.jpeg"} width={'100%'} alt={"contact us background"} className=""/>
                <div className="mx-auto our-mission md:w-4/5 text-center relative md:bottom-[90px] md:mt-[0] mt-10">
                    <h1 className="text-4.5xl font-bold bg-blue-50 text-white pt-[4px] pb-[8px]">Contact Us</h1>
                </div>
                <div className={"md:w-4/5 mx-auto"}>
                    {/* <div className={"flex items-center"}>
                        <div className={"bg-pink-50 rounded-full p-5"}>
                            <img width="47" height="47" className={""} alt={"phone"}
                                 src={"/images/icons/call.svg"}/>
                        </div>
                        <span className={"ml-4 text-2xl font-semibold"}>{STRINGS.PHONE}</span>
                    </div> */}
                    <div className={"flex items-center mt-4"}>
                        <div className={"bg-pink-50 rounded-full p-5 max-w-[70.1px] xs:max-w-[unset]"}>
                            <img width="47" height="47" className={""} alt={"email"}
                                 src={"/images/icons/email.svg"}/>
                        </div>
                        <a href="mailto:info@agegroup.io" target="_blank" rel="noreferrer" className={"ml-4 text-2xl font-semibold"}>{URL.INFO_EMAIL}</a>
                    </div>
                    <div className={"flex items-center mt-4"}>
                        <div className={"bg-pink-50 rounded-full p-5 max-w-[70.1px] xs:max-w-[unset]"}>
                            <img width="47" height="47" className={""} alt={"address"}
                                 src={"/images/icons/pin.svg"}/>
                        </div>
                        <span className={"ml-4 text-2xl font-semibold"}>{STRINGS.ADDRESS_STRING}</span>
                    </div>
                    <div className="my-8">
                        {contactSubmitted && <div className={"text-3xl font-semibold"}>
                            Thank you for sending us message, we will contact you soon!!
                        </div>}
                        {!contactSubmitted && <div>
                            <div className={"text-2xl font-semibold mb-2 text-center"}>Reach Us</div>
                            <helmet>
                                <script type="text/javascript">{`var submitted = false;`}</script>
                            </helmet>
                            <iframe title={"form"} name="hidden_iframe" id="hidden_iframe" style={{display:'none'}}
                                    onLoad={() => {if(submissionStart) setContactSubmitted(true)}}/>
                            <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSeCR6CseUTXtkPNIIJ2lBh_-O9PBRH8XtE_krk17wiJ7giBDw/formResponse"
                                                    method="POST"
                                                    id="mG61Hd" onSubmit={() => {setSubmissionStart(true)}} target="hidden_iframe">
                            <div>
                                <span className="uppercase text-sm text-gray-600 font-bold">Name</span>
                                <input
                                    className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    name="entry.1980009539"
                                    type="text" required="true" placeholder=""/>
                            </div>
                            <div className="mt-8">
                                <span className="uppercase text-sm text-gray-600 font-bold">Email</span>
                                <input
                                    className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    name="entry.1165185470"
                                    type="text" required="true" placeholder=""/>
                            </div>
                            <div className="mt-8">
                                <span className="uppercase text-sm text-gray-600 font-bold">Contact Number</span>
                                <input
                                    className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    name="entry.1684700761" required="true"
                                    type="text"/>
                            </div>
                            <div className="mt-8">
                                <span className="uppercase text-sm text-gray-600 font-bold">Message</span>
                                <textarea
                                    className="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    name="entry.416327937" required="true"
                                    spellCheck="false"></textarea>
                            </div>
                            <div className="mt-8">
                                <button
                                    className="uppercase text-sm font-bold tracking-wide bg-pink-50 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
                                    Send Message
                                </button>
                            </div>
                            </form></div>}

                    </div>
                </div>
            </div>
            {/*<div
                className="max-w-screen-xl px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg">
                <div className="flex flex-col justify-between">
                    <div>
                        <h2 className="text-4xl lg:text-5xl font-bold leading-tight">Lets talk about everything!</h2>
                        <div className="text-gray-700 mt-8">
                            Hate forms? Send us an <span className="underline">email</span> instead.
                        </div>
                    </div>
                    <div className="mt-8 text-center">

                    </div>
                </div>
                <div className="">
                    {contactSubmitted && <div className={"text-3xl font-semibold"}>
                        Thank you for sending us message, we will contact you soon!!
                    </div>}
                    {!contactSubmitted && <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSeCR6CseUTXtkPNIIJ2lBh_-O9PBRH8XtE_krk17wiJ7giBDw/formResponse"
                          method="POST"
                          id="mG61Hd" onSubmit={() => {setContactSubmitted(true)}}>
                        <div>
                            <span className="uppercase text-sm text-gray-600 font-bold">Name</span>
                            <input
                                className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                name="entry.1980009539"
                                type="text" required="true" placeholder=""/>
                        </div>
                        <div className="mt-8">
                            <span className="uppercase text-sm text-gray-600 font-bold">Email</span>
                            <input
                                className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                name="entry.1165185470"
                                type="text" required="true" placeholder=""/>
                        </div>
                        <div className="mt-8">
                            <span className="uppercase text-sm text-gray-600 font-bold">Contact Number</span>
                            <input
                                className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                name="entry.1684700761" required="true"
                                type="text"/>
                        </div>
                        <div className="mt-8">
                            <span className="uppercase text-sm text-gray-600 font-bold">Message</span>
                            <textarea
                                className="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                name="entry.416327937" required="true"
                                spellCheck="false"></textarea>
                        </div>
                        <div className="mt-8">
                            <button
                                className="uppercase text-sm font-bold tracking-wide bg-pink-50 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline">
                                Send Message
                            </button>
                        </div>
                    </form>}

                </div>
            </div>*/}
        </Layout>
    );
}

export default ContactUs;
